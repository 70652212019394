import { createRouter, createWebHistory } from "vue-router";
import axios from "axios"; // Add this line to import axios
import store from "../store";
import LoginPage from "../views/LoginView.vue";
import DownloadPage from "../views/DownloadView.vue";
import UploadPage from "../views/UploadView.vue";
import NotFoundPage from "../views/NotFoundView.vue";
import ResharePage from "../views/ReshareView.vue";

export const routes = [
  {
    path: "/",
    name: "Home", // Add a name for the route
    component: { template: "<div></div>" }, // Add an empty component for the root path
    beforeEnter: (to, from, next) => {
      console.log(to + from + next);
      next({ name: "Not Found" });
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/upload",
    name: "Upload",
    component: UploadPage,
  },
  {
    path: "/download/:id",
    name: "Download",
    component: DownloadPage,
    beforeEnter: (to, from, next) => {
      console.log(to, from, next);
      checkFile(to.params.id, next);
    },
  },
  {
    path: "/404",
    name: "Not Found",
    component: NotFoundPage,
  },
  {
    path: "/reshare",
    name: "Reshare",
    component: ResharePage,
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireToken) {
    if (store.getters.getToken == null) {
      next({ name: "Login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

function checkFile(fileIdentifier, next) {
  axios
    .post("https://secure.sboss.nl:8001/file/exist", {
      identifier: fileIdentifier,
    })
    .then(function (response) {
      if (response.status == 404) {
        next({ name: "Not Found" });
      } else {
        next();
      }
    })
    .catch(function () {
      next({ name: "Not Found" });
    });
}

export default router;

<template>
  <div class="download-container">
    <img
      src="@/assets/company-banner.png"
      alt="Company Banner"
      class="company-banner"
    />

    <v-card class="download-card">
      <v-card-title class="download-title" color="red darken-2 white--text"
        >Download File</v-card-title
      >
      <v-text-field
        v-model="key"
        class="download-text-field"
        hide-details
        label="Enter Key"
        solo-inverted
      ></v-text-field>
      <v-alert
        :value="error"
        v-show="error"
        color="red"
        type="error"
        class="download-alert"
        transition="scale-transition"
        >{{ error }}</v-alert
      >
      <v-alert
        :value="success"
        v-show="success"
        color="green"
        type="success"
        class="download-alert"
        transition="scale-transition"
        >{{ success }}</v-alert
      >
      <v-btn
        @click="downloadFile"
        class="download-btn"
        color="red darken-2 white--text"
        >Download File</v-btn
      >
    </v-card>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
export default {
  data() {
    return {
      success: null,
      error: null,
      cardBackground: "linear-gradient(to right, #000 50%, #fff 50%)",
      key: "", // Store the key entered by the user
      filename: null,
    };
  },
  methods: {
    async downloadFile() {
      const fileId = this.$route.params.id;
      const comp = this;
      let key = this.key;
      if (key == null || key == "") {
        key = "test";
      }

      try {
        const response = await this.axios.get(
          `https://secure.sboss.nl:8001/file?identifier=${fileId}&key=${key}`,
          {
            responseType: "json",
          }
        );

        const { filename, data } = response.data;

        comp.filename = filename;
        // Decode the base64 string
        const decodedData = atob(data);

        // Convert the decoded data to a Uint8Array
        const uint8Array = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
          uint8Array[i] = decodedData.charCodeAt(i);
        }

        // Create a Blob from the Uint8Array
        const blob = new Blob([uint8Array], {
          type: "application/octet-stream",
        });

        // Remove the ".enc" extension
        const cleanFilename = filename.replace(/\.enc$/, "");

        // Save the file with the correct filename
        saveAs(blob, cleanFilename, { autoBom: false });

        // Verify the file on the server
        await this.axios.post(`https://secure.sboss.nl:8001/file/verify`, {
          filename: comp.filename,
        });

        comp.success = "Success! File has been downloaded and verified.";
      } catch (error) {
        console.error("Error during file download:", error);
        comp.error = "Error during file download.";
      }
    },
  },
};
</script>

<style scoped>
.download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.company-banner {
  margin-top: 30vh;
  max-height: 150px; /* Adjust the maximum height as needed */
}

.download-card {
  width: 400px;
  text-align: center;
  padding: 20px;
  background-color: white;
}

.download-title {
  font-size: 18px;
  margin-bottom: 15px;
}

.download-text-field {
  width: 100%;
  margin-bottom: 15px;
}

.download-alert {
  width: 100%;
  margin-bottom: 15px;
}

.download-btn {
  width: 100%;
}
</style>

import { createStore } from "vuex";

export default createStore({
  state: {
    theme: "dark",

    isAuthenticated: false,
    token: null,
    user: null,
    jwtToken: null,
  },
  getters: {
    getToken: (state) => state.token,
    getUser: (state) => state.user,
    getTheme: (state) => state.theme,
    getJWTToken: (state) => state.jwtToken,
  },
  mutations: {
    setJWTToken(state, token) {
      state.jwtToken = token;
    },
    logOut(state) {
      state.jwtToken = null;
      state.token = null;
    },
    changeTheme(state) {
      state.theme = state.theme ? "light" : "dark";
    },
    SET_AUTHENTICATION(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated;
    },
    SET_ACCESS_TOKEN(state, token) {
      state.token = token;
    },
    SET_ID_TOKEN(state, user) {
      state.user = user;
    },
  },
  actions: {},
  modules: {
    isAuthenticated: (state) => state.isAuthenticated,
    token: (state) => state.token,
    user: (state) => state.user,
    loginResponse: (state) => ({
      success: state.isAuthenticated,
      token: state.token,
      user: state.user,
    }),
  },
});

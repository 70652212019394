<template>
  <div class="container">
    <v-card class="center-card">
      <v-card-title>Upload & Share securely:</v-card-title>
      <v-file-input
        v-model="fileInput"
        style="margin-right: 4%; width: 79%; margin-top: 2%"
        accept=".txt, .pdf, .iso, .ova, .vhdx, .docx, .zip, image/*"
        label="File input"
      ></v-file-input>
      <v-text-field
        v-model="email"
        style="width: 75%"
        label="Email address:"
      ></v-text-field>
      <v-text-field
        v-model="emailSender"
        style="width: 75%"
        label="Email address from sender:"
      ></v-text-field>
      <v-checkbox
        v-model="encryption"
        @click="removeTimelimitonNoEncryption"
        label="Does this file need encryption?"
      ></v-checkbox>
      <v-checkbox
        v-model="timelimit"
        :disabled="!encryption"
        label="Does this file need a time limit?"
      ></v-checkbox>
      <v-alert
        :value="error"
        v-show="error"
        color="red"
        type="error"
        style="width: 37vw; margin: auto; margin-bottom: 2%; text-align: center"
        transition="scale-transition"
        >{{ this.error }}</v-alert
      >
      <v-alert
        :value="success"
        v-show="success"
        color="green"
        type="success"
        style="width: 37vw; margin: auto; margin-bottom: 2%; text-align: center"
        transition="scale-transition"
        >{{ this.success }}</v-alert
      >
      <v-btn @click="uploadAndSend" class="center-btn">Upload & Send</v-btn>
    </v-card>
    <v-card class="center-card">
      <v-card-title>Reshare</v-card-title>
      <v-btn @click="gotoReshare">Reshare Files</v-btn>
    </v-card>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  data() {
    return {
      fileInput: null,
      email: "",
      emailSender: "",
      timelimit: true,
      encryption: true,
      statuscode: null,
      success: null,
      error: null,
      jwksUri: null,
      publicKeys: [],
      rules: {
        required: (value) => !!value || "Required.",
        checkresponse:
          this.statuscode == null ||
          this.statuscode == 200 ||
          "Login not found",
      },
    };
  },
  components: {},
  async mounted() {
    const tenantId = this.$store.getters.getUser;
    if (tenantId) {
      this.jwksUri = `https://login.microsoftonline.com/${tenantId}/discovery/keys?appid=a27a8d5a-3280-4258-a71b-f706ac917a1d`;
      await this.getPublicKeys();
      this.verifyAccessToken();
    } else {
      console.error("Tenant ID not found in the ID token.");
    }
  },
  methods: {
    removeTimelimitonNoEncryption() {
      if (this.encryption) {
        this.timelimit = false;
      }
    },
    gotoReshare() {
      this.$router.push("reshare");
    },
    async getPublicKeys() {
      try {
        const response = await this.axios.get(this.jwksUri);
        response.data.keys.forEach((key) => this.publicKeys.push(key));
      } catch (error) {
        console.error("Failed to retrieve public key:", error.message);
      }
    },
    verifyAccessToken() {
      if (!this.publicKeys) {
        console.error("Public keys not available.");
        this.$router.push("login");
      }
      let accessToken = this.$store.getters.getToken;

      try {
        const decodedToken = VueJwtDecode.decode(accessToken);
        this.emailSender = decodedToken.unique_name;
        let keyMatchFound = false;
        console.log("checking keys");

        console.log("decodedkey: " + decodedToken.kid);
        this.publicKeys.forEach((publicKey) => {
          console.log(publicKey);
          if (decodedToken.kid === publicKey.kid) {
            keyMatchFound = true;
          }
        });
        if (!keyMatchFound) {
          this.$router.push("login");
        }
        try {
          // Simulate API request
          // Replace the following line with your actual API endpoint and payload
          let comp = this;
          let user = this.$store.getters.getUser;
          this.axios
            .post("https://secure.sboss.nl:8001/login", {
              tenantid: user,
              clientid: "a27a8d5a-3280-4258-a71b-f706ac917a1d",
              accesstokenkid: decodedToken.kid,
            })
            .then(function (response) {
              console.log(response);
              if (response.status == 200) {
                comp.$store.commit("setJWTToken", response.data.token);
                comp.error = null;
              }
            })
            .catch(function () {
              console.log("catch triggered");
              comp.$router.push("login");
            });
        } catch {
          console.log("error");
        }
      } catch (error) {
        console.error("Token verification failed:", error.message);
      }
    },
    async uploadAndSend() {
      let comp = this;
      const formData = new FormData();
      formData.append("file", this.fileInput[0]);
      formData.append("email", this.email);
      formData.append("emailSender", this.emailSender);
      formData.append("encryption", this.encryption);
      formData.append("perma", !this.timelimit);
      console.log(formData.get("perma"));
      try {
        // Simulate API request
        // Replace the following line with your actual API endpoint and payload
        this.axios.defaults.headers.common["Authorization"] =
          `Bearer ` + this.$store.getters.getJWTToken;
        await this.axios
          .post("https://secure.sboss.nl:8001/file", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(function (response) {
            if (response.status == 200) {
              comp.success = "File uploaded and sent successfully!";
              comp.error = null;
            }
          })
          .catch(function () {
            comp.error = "Error uploading and sending file.";
            comp.success = null;
          });
      } catch {
        console.log("error");
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 100% of the viewport height */
}

.center-card {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
}

.center-btn {
  margin-bottom: 15px; /* Add some top margin for spacing */
}
</style>

<template>
  <v-container class="text-center">
    <v-row align="center" justify="center">
      <v-col cols="12">
        <img src="@/assets/404.png" alt="404 Not Found" class="error-image" />
        <h1 class="headline font-weight-bold mb-4">404 Not Found</h1>
        <p class="subheading mb-6">
          The page you are looking for might be under construction or does not
          exist.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.error-image {
  width: 100%;
  max-width: 400px;
  margin-bottom: 2rem;
}

.headline {
  font-size: 2.5rem;
}

.subheading {
  font-size: 1.2rem;
}
</style>

<template>
  <div class="download-container">
    <img
      src="@/assets/office.png"
      alt="Company Banner"
      class="company-banner"
    />
    <v-card class="login-card">
      <v-card-title>Login:</v-card-title>
      <v-card-text>
        <v-row justify="center" v-if="loading">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-row>
        <v-row justify="center" v-else>
          <v-btn
            color="red darken-2 white--text"
            @click="login"
            class="login-btn"
            >Login</v-btn
          >
        </v-row>
        <v-row justify="center" v-if="permissionError" class="error-login">
          You do not have permission to access this page.
        </v-row>
        <v-row justify="center" v-else>
          <v-btn
            color="red darken-2 white--text"
            @click="logout"
            class="logout-btn"
            >Logout</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import * as msal from "@azure/msal-browser";

export default {
  data() {
    return {
      error: null,
      loading: false,
      permissionError: false,
      msalInstance: null,
    };
  },
  mounted() {
    this.initializeMsal();
  },
  methods: {
    async initializeMsal() {
      try {
        // Initialize MSAL configuration
        const msalConfig = {
          auth: {
            clientId: "a27a8d5a-3280-4258-a71b-f706ac917a1d",
            authority:
              "https://login.microsoftonline.com/c2610267-24a6-459d-a03a-dbe828a6d229",
          },
          cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true,
          },
        };

        // Create MSAL instance and initialize
        this.msalInstance = new msal.PublicClientApplication(msalConfig);
        await this.msalInstance.initialize(); // Ensure initialization is complete
        console.log("MSAL instance initialized successfully.");

        // Handle redirect callback
        const response = await this.msalInstance.handleRedirectPromise();
        console.log("Redirect response:", response);

        if (response) {
          // Response contains tokens or errors from the redirect
          const accessToken = response.accessToken;
          const tenantId = response.tenantId;

          // Store tokens or redirect to home page (modify as needed)
          this.$store.commit("SET_ACCESS_TOKEN", accessToken);
          this.$store.commit("SET_ID_TOKEN", tenantId);

          // Redirect to the next page (replace 'next-page' with your actual route name)
          this.$router.push({ name: "Upload" });
        } else {
          console.log("No redirect response received.");
        }
      } catch (error) {
        console.error("MSAL initialization error:", error);
        this.error = true;
      }
    },
    async login() {
      if (this.loading) {
        // If loading is true, an interaction is already in progress
        return;
      }

      this.loading = true;
      this.error = null;

      try {
        // MSAL login logic
        await this.msalInstance.loginRedirect();
      } catch (error) {
        console.error("Login error:", error);
        this.error = true;
      } finally {
        this.loading = false;
      }
    },

    async logout() {
      if (this.loading) {
        // If loading is true, an interaction is already in progress
        return;
      }

      this.loading = true;

      try {
        // MSAL logout logic
        await this.msalInstance.logoutRedirect();

        // Set isAuthenticated to false and perform any additional cleanup
        this.$store.commit("isAuthenticated", false);
        // Additional cleanup or redirect logic can be added here

        console.log("Logged out");
      } catch (error) {
        console.error("Logout error:", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.download-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background-color: black;
}

.company-banner {
  margin-top: 30vh;
  max-width: 35vw;
  margin-bottom: 2vh;
}

.login-card {
  width: 400px;
  text-align: center;
  padding: 20px;
  background-color: white;
}

.login-btn {
  width: 100%;
  background-color: "red darken-2";
  color: white;
  margin-top: 15px;
}

.logout-btn {
  width: 100%;
  background-color: "red darken-2";
  color: white;
  margin-top: 15px;
}

.error-login {
  color: red;
  margin-top: 15px;
}
</style>

<template>
  <div class="container">
    <v-card class="center-card">
      <v-card-title> Reshare Files</v-card-title>
      <v-data-table-virtual
        :headers="headers"
        :items="files"
        :items-per-page="15"
        class="elevation-1"
        height="100%"
        @click:row="reshareFiles"
      >
      </v-data-table-virtual>

      <v-pagination
        v-model="currentPage"
        :length="Math.ceil(files.length / 15)"
      ></v-pagination>
      <v-dialog v-model="reshareDialog" max-width="500px">
        <v-card>
          <v-card-title>Reshare File</v-card-title>
          <v-card-text>
            <v-textarea
              v-model="resharelist"
              label="Email Addresses,  one per line (press enter to goto next line)."
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="closeReshareDialog">Cancel</v-btn>
            <v-btn @click="ReshareFile" color="primary">Reshare</v-btn>
          </v-card-actions>
          <v-alert
            v-show="success"
            :value="success"
            color="green"
            type="success"
            style="width: 96%; margin-top: 2%"
            transition="scale-transition"
          >
          </v-alert>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
export default {
  data() {
    return {
      fileInput: null,
      currentPage: null,
      resharelist: null,
      reshareDialog: false,
      email: "",
      selectedRow: null,
      timelimit: true,
      encryption: true,
      success: null,
      error: null,
      jwksUri: null,
      files: [],
      headers: [
        {
          title: "Filename",
          align: "start",
          sortable: "true",
          key: "Filename",
        },
        {
          title: "Upload Time",
          align: "start",
          sortable: "true",
          key: "timeStamp",
        },
      ],
    };
  },
  components: {},
  async mounted() {
    const tenantId = this.$store.getters.getUser;
    if (tenantId) {
      this.jwksUri = `https://login.microsoftonline.com/${tenantId}/discovery/keys?appid=a27a8d5a-3280-4258-a71b-f706ac917a1d`;
      await this.getPublicKey();
      this.verifyAccessToken();
    } else {
      console.error("Tenant ID not found in the ID token.");
    }
    this.getFiles();
  },
  methods: {
    ReshareFile() {
      const emails = this.resharelist.split("\n").map((email) => email.trim());

      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getJWTToken;

      emails.forEach((email) => {
        this.axios
          .post("https://secure.sboss.nl:8001/file/reshare", {
            filename: this.selectedRow.Filename,
            email: email,
          })
          .then(async function (response) {
            if (response.status == 200) {
              comp.success = "successfully resend files!";
            }
          })
          .catch((error) => {
            console.log("error = " + error);
          });
      });
    },
    reshareFiles(event, item) {
      this.selectedRow = item.item;
      this.reshareDialog = true;
    },
    closeReshareDialog() {
      this.reshareDialog = false;
      this.selectedRow = null;
    },
    formatTimestamp(timestamp) {
      const formattedDate = new Date(timestamp).toLocaleString();
      return formattedDate.replace(",", ""); // Remove comma after the day
    },
    async getPublicKeys() {
      try {
        const response = await this.axios.get(this.jwksUri);
        response.data.keys.forEach((key) => this.publicKeys.push(key));
      } catch (error) {
        console.error("Failed to retrieve public key:", error.message);
      }
    },
    verifyAccessToken() {
      if (!this.publicKeys) {
        console.error("Public keys not available.");
        this.$router.push("login");
      }
      let accessToken = this.$store.getters.getToken;

      try {
        const decodedToken = VueJwtDecode.decode(accessToken);
        let keyMatchFound = false;
        console.log("checking keys");

        console.log("decodedkey: " + decodedToken.kid);
        this.publicKeys.forEach((publicKey) => {
          console.log(publicKey);
          if (decodedToken.kid === publicKey.kid) {
            keyMatchFound = true;
          }
        });
        if (!keyMatchFound) {
          this.$router.push("login");
        }
        try {
          // Simulate API request
          // Replace the following line with your actual API endpoint and payload
          let comp = this;
          let user = this.$store.getters.getUser;
          this.axios
            .post("https://secure.sboss.nl:8001/login", {
              tenantid: user,
              clientid: "a27a8d5a-3280-4258-a71b-f706ac917a1d",
              accesstokenkid: decodedToken.kid,
            })
            .then(function (response) {
              console.log(response);
              if (response.status == 200) {
                comp.$store.commit("setJWTToken", response.data.token);
                comp.error = null;
              }
            })
            .catch(function () {
              console.log("catch triggered");
              comp.$router.push("login");
            });
        } catch {
          console.log("error");
        }
      } catch (error) {
        console.error("Token verification failed:", error.message);
      }
    },
    async getFiles() {
      let comp = this;
      this.axios.defaults.headers.common["Authorization"] =
        `Bearer ` + this.$store.getters.getJWTToken;
      console.log("token: " + this.$store.getters.getJWTToken);
      this.axios
        .get("https://secure.sboss.nl:8001/file/reshare")
        .then(async function (response) {
          if (response.status == 200) {
            let genitems = response.data;
            genitems.forEach((item) => {
              item.timeStamp = comp.formatTimestamp(item.timeStamp);
            });
            comp.files = genitems;
          }
        })
        .catch(function () {
          comp.error = "Error loading messages!";
        });
    },
  },
  computed: {},
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* 100% of the viewport height */
}

.center-card {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
}

.center-btn {
  margin-bottom: 15px; /* Add some top margin for spacing */
}
</style>
